import { useCallback, useMemo } from "react";

import { useToast } from "@bees-grow-shared/business-components";
import {
  accountSharedService,
  agentCallSharedService,
  ClassificationEnum,
  ctiSharedService,
  phoneRankSharedService,
  PhoneResponseType,
  PocCallOrigin,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { PhoneOff, ThumbsDown, ThumbsUp } from "@hexa-ui/icons";

import { usePhoneActionModal } from "@/contexts/PhoneActionModalContext/PhoneActionModalContext";

import { usePhoneMessages } from "../usePhoneMessages/usePhoneMessages";

interface PhoneIconsProps {
  icon: React.ReactElement;
  onClick?: () => void;
}

interface VoteIconsProps {
  voteIcons: Array<PhoneIconsProps>;
  handleActivatePhone: () => void;
}

export function useVoteIcons(phoneListData: PhoneResponseType): VoteIconsProps {
  const toast = useToast();
  const agentCallService = agentCallSharedService();
  const ctiService = ctiSharedService();
  const phoneRankService = phoneRankSharedService();
  const accountService = accountSharedService();

  const { accountId, vendorId } = useSharedUnit(accountService);
  const { pocCallOrigin } = useSharedUnit(agentCallService);
  const { isConnected: isCTIConnected, calledNumbers } =
    useSharedUnit(ctiService);

  const ctiEnabled = true;

  const { handleOpenModal } = usePhoneActionModal();

  const { phoneNumber: currentPhone } = phoneListData;
  const { getErrorMessage, getSuccessMessage } = usePhoneMessages(currentPhone);

  const voteActions = useCallback(
    (classification) => {
      const { phoneContact, phoneType } = phoneListData;

      phoneRankService
        .updatePhoneRank({
          params: {
            phoneNumber: currentPhone,
            accountId,
            vendorId,
          },
          phone: {
            classification,
            contactName: phoneContact,
            phoneNumber: currentPhone,
            phoneType,
          },
        })
        .then(() => {
          const message = getSuccessMessage(classification);
          toast({
            showCloseButton: true,
            type: "success",
            message,
          });
        })
        .catch(() => {
          const message = getErrorMessage(classification);
          toast({
            showCloseButton: true,
            type: "error",
            message,
          });
        });
    },
    [
      accountId,
      currentPhone,
      getErrorMessage,
      getSuccessMessage,
      phoneListData,
      phoneRankService,
      toast,
      vendorId,
    ],
  );

  const handleActivatePhone = useCallback(() => {
    handleOpenModal({
      phone: currentPhone,
      type: ClassificationEnum.ACTIVATE,
      onClick: () => voteActions(ClassificationEnum.ACTIVATE),
    });
  }, [handleOpenModal, currentPhone, voteActions]);

  const voteIcons = useMemo(() => {
    const icons: Array<PhoneIconsProps> = [];
    const isCurrentPhoneCalled = calledNumbers.find(
      (calledNumber) => calledNumber.phoneNumber === currentPhone,
    );

    const addInactiveIcon = () => {
      icons.unshift({
        icon: <PhoneOff size="tiny" key="inactive" />,
        onClick: () =>
          handleOpenModal({
            phone: phoneListData.phoneNumber,
            type: ClassificationEnum.INACTIVATE,
            onClick: () => voteActions(ClassificationEnum.INACTIVATE),
          }),
      });
    };

    const addCommonIcons = () => {
      icons.push(
        {
          icon: <ThumbsDown size="tiny" key="no_answer" />,
          onClick: () => voteActions(ClassificationEnum.NO_ANSWER),
        },
        {
          icon: <ThumbsUp size="tiny" key="successful_call" />,
          onClick: () => voteActions(ClassificationEnum.GOOD),
        },
      );
    };

    if (
      ctiEnabled &&
      isCTIConnected &&
      isCurrentPhoneCalled &&
      !isCurrentPhoneCalled.isCallFailed &&
      pocCallOrigin !== PocCallOrigin.AUTO_DIALER
    ) {
      addInactiveIcon();
      addCommonIcons();
    }

    if (
      ((ctiEnabled && !isCTIConnected) || !ctiEnabled) &&
      pocCallOrigin !== PocCallOrigin.AUTO_DIALER
    ) {
      addInactiveIcon();
      addCommonIcons();
    }

    if (pocCallOrigin === PocCallOrigin.AUTO_DIALER) {
      addInactiveIcon();
    }

    return icons;
  }, [
    calledNumbers,
    ctiEnabled,
    currentPhone,
    handleOpenModal,
    isCTIConnected,
    phoneListData.phoneNumber,
    pocCallOrigin,
    voteActions,
  ]);

  return {
    handleActivatePhone,
    voteIcons,
  };
}
